import React from 'react'

import Login from '../components/Login'
import { Layout, Navbar } from '../Layout'

const login = () => (
	<Layout
		title="Login"
		navbar={<Navbar position="absolute"/>}
		content={<Login/>}
		footer={null}
	/>
)

export default login
